import { createSelector } from 'reselect';
import uniqBy from 'lodash/fp/uniqBy';
import groupBy from 'lodash/fp/groupBy';
import partition from 'lodash/fp/partition';

import { RootState } from 'app-wrapper/store';
import {
  priceByBol, occurrenceAdditional, priceByContainer, typeFee, PermissionAttributePolicy, EShippingPartyTypes,
} from 'shipment-operations/constants';
import {
  ContainerViewDTM,
  InvoicesChargeDTM,
  LinkedCreditNoteDTM,
  ShippingPartyDTM,
} from 'shipment-operations/models/dtm';
import { makePaymentSelectors } from 'shipment-operations/repository/store/MakePayment';

import { calculateInvoiceSum } from 'shipment-operations/view/pages/ShipmentBillingInvoice/utils/calculateSum';
import { hasAccess } from 'app-wrapper/utils';

const parseContainers = (data: InvoicesChargeDTM[]) => {
  const filtered = data.filter((item) => item.charge.container);
  if (filtered.length) {
    const prepared = filtered.map((item) => (item.charge.container ? ({
      number: item.charge.container.number,
      type: item.charge.container.type,
      id: item.charge.container.id,
    }) : null));
    return prepared.length ? prepared.filter((el) => el) as ContainerViewDTM[] : [];
  }
  return [];
};

const rootState = (state: RootState) => state;

const localState = (state: RootState) => state.shipmentBillingInvoice;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getShipmentParties = createSelector(
  localState,
  (state) => state.shipmentParties,
);

const getIsLoadingCompanies = createSelector(
  getShipmentParties,
  (companies) => companies.isLoading,
);

const getGlobalLoading = createSelector(
  getIsLoading,
  getIsLoadingCompanies,
  (isLoading, isLoadingCompanies) => isLoading || isLoadingCompanies,
);

const getError = createSelector(
  localState,
  (state) => state.error,
);

const getInvoice = createSelector(
  localState,
  (state) => state.invoice,
);

const getCreditNote = createSelector(
  localState,
  (state) => state.creditNote,
);

const getNotes = createSelector(
  getCreditNote,
  (state) => state.notes,
);

const getIsOpenCreateCreditNote = createSelector(
  localState,
  (state) => state.isOpenCreditNote,
);

const getIsOpenPayment = createSelector(
  localState,
  (state) => state.isOpenPayment,
);

const getUpdatedItems = createSelector(
  getCreditNote,
  (state) => state.updatedItems,
);

const getCreatedCreditNote = createSelector(
  getCreditNote,
  (note) => note.createdCreditNote,
);

const getCreditNoteError = createSelector(
  getCreditNote,
  (note) => note.error,
);

const getCreditNoteErrorTitle = createSelector(
  getCreditNote,
  (note) => note.errorTitle,
);

const getCreditNoteLoading = createSelector(
  getCreditNote,
  (note) => note.isLoading,
);

const getCreditNoteSuccess = createSelector(
  getCreditNote,
  (note) => note.success,
);

const getLinkedCreditNotes = createSelector(
  localState,
  (state) => state.linkedCreditNotes,
);

const getLinkedCreditNotesItems = createSelector(
  getLinkedCreditNotes,
  (creditNote) => creditNote.items,
);

const preparedCreditNotesForTable = createSelector(
  getLinkedCreditNotesItems,
  (items) => items.map((item) => ({
    ...item,
    key: item.id,
  })),
);

const getLinkedCreditNotesLoading = createSelector(
  getLinkedCreditNotes,
  (creditNote) => creditNote.isLoading,
);

const getLinkedCreditNotesError = createSelector(
  getLinkedCreditNotes,
  (creditNote) => creditNote.isError,
);

const getStatus = createSelector(
  getInvoice,
  (item) => item?.status,
);

const getNumber = createSelector(
  getInvoice,
  (item) => item?.number,
);

const getCharges = createSelector(
  getInvoice,
  (invoice) => invoice?.charges,
);

const getAppliedCharges = createSelector(
  getCharges,
  (data) => data?.filter((item) => item.charge.applied),
);

const getAdditionalData = createSelector(
  getAppliedCharges,
  (charges) => partition((item) => item.charge.additional, charges),
);

const getServicesData = createSelector(
  getAdditionalData,
  ([, data]) => partition((item) => item.charge.chargeCode.occurrence === occurrenceAdditional, data),
);

const getFeesData = createSelector(
  getServicesData,
  ([, data]) => partition((item) => (item.charge.chargeCode.type === typeFee || item.charge.priceBy === priceByBol), data),
);

const getTransportationData = createSelector(
  getFeesData,
  ([, data]) => data,
);

const getServicesContainersData = createSelector(
  getServicesData,
  ([charges]) => charges.filter((item) => item.charge.priceBy === priceByContainer),
);

const getServicesDataRest = createSelector(
  getServicesData,
  ([charges]) => charges.filter((item) => item.charge.priceBy !== priceByContainer),
);

const getAdditionalContainersData = createSelector(
  getAdditionalData,
  ([data]) => data.filter((item) => item.charge.priceBy === priceByContainer),
);

const getAdditionalRestData = createSelector(
  getAdditionalData,
  ([data]) => data.filter((item) => item.charge.priceBy !== priceByContainer),
);

const getTransportationDataSum = createSelector(
  getTransportationData,
  (data) => calculateInvoiceSum(data),
);

const getServicesDataSum = createSelector(
  getServicesData,
  ([data]) => calculateInvoiceSum(data),
);

const getFeesDataSum = createSelector(
  getFeesData,
  ([data]) => calculateInvoiceSum(data),
);

const getAdditionalDataSum = createSelector(
  getAdditionalData,
  ([data]) => calculateInvoiceSum(data),
);

const getPreparedServicesContainersData = createSelector(
  getServicesContainersData,
  (data) => parseContainers(data),
);

const getTransportationContainers = createSelector(
  getTransportationData,
  (data) => parseContainers(data),
);

const getAdditionalContainers = createSelector(
  getAdditionalContainersData,
  (data) => parseContainers(data),
);

const uniqTransportationContainers = createSelector(
  getTransportationContainers,
  (containers) => uniqBy('id', containers),
);

const uniqAdditionalContainers = createSelector(
  getAdditionalContainers,
  (containers) => uniqBy('id', containers),
);

const uniqServicesContainers = createSelector(
  getPreparedServicesContainersData,
  (containers) => uniqBy('id', containers),
);

const groupedTransportationContainers = createSelector(
  getTransportationData,
  (data) => groupBy((item) => item.charge.container?.id, data),
);

const groupedAdditionalContainers = createSelector(
  getAdditionalContainersData,
  (data) => groupBy((item) => item.charge.container?.id, data),
);

const groupedServicesContainers = createSelector(
  getServicesContainersData,
  (data) => groupBy((item) => item.charge.container?.id, data),
);

const getSelectedItems = createSelector(
  getCreditNote,
  (creditNote) => creditNote.selectedItems,
);

const getInvoiceForPayment = createSelector(
  getInvoice,
  makePaymentSelectors.getReceivedAmount,
  (invoice, receivedAmount) => ([{
    id: invoice?.number,
    dueDate: invoice?.dueDate,
    total: invoice?.billed,
    balance: invoice?.balance,
    payment: receivedAmount > (invoice?.balance || 0) ? invoice?.balance : receivedAmount,
  }]),
);

const getInvoiceBalance = createSelector(
  getInvoice,
  (invoice) => invoice?.balance,
);

const getUsedAmount = createSelector(
  makePaymentSelectors.getReceivedAmount,
  getInvoiceBalance,
  (received, balance) => (received > (balance || 0) ? (balance || 0) : received),
);

const getLeftAmount = createSelector(
  makePaymentSelectors.getReceivedAmount,
  getUsedAmount,
  (received, used) => received - used,
);

const getLinkedPayments = createSelector(
  localState,
  (state) => state.linkedPayments,
);

const getPaymentsTable = createSelector(
  getInvoice,
  getLinkedPayments,
  (invoice, payments) => invoice?.transactions?.map((item) => {
    const number = payments?.find((elem) => elem.id === item.source.id)?.number;

    return (LinkedCreditNoteDTM.fromPlain({
      number,
      id: item.source?.id,
      amount: item.amount,
      createdAt: item.createdAt,
    }));
  }),
);

const getSumOfUpdatedItems = createSelector(
  getUpdatedItems,
  (items) => items.reduce((acc, item) => acc + parseInt(item.amount, 10), 0),
);

const getCompaniesList = createSelector(
  getShipmentParties,
  (parties) => parties.companies,
);

const getLinkedCompanyFrom = createSelector(
  getInvoice,
  getCompaniesList,
  (invoice, companies) => companies?.find((item) => item.company?.id === invoice?.billFrom?.id),
);

const getLinkedCompanyTo = createSelector(
  getInvoice,
  getCompaniesList,
  (invoice, companies) => companies?.find((item) => item.company?.id === invoice?.billTo?.id),
);

const getMakePaymentButtonAvailability = createSelector(
  localState,
  (state) => hasAccess(state.permissions.shipmentsBillingInvoicePayables.makePaymentButton, PermissionAttributePolicy.WRITE),
);

const getIsVATVisible = createSelector(
  rootState,
  getInvoice,
  getShipmentParties,
  (state, invoice, parties) => {
    const { shipment } = state.shipment;
    const bookingAgent: ShippingPartyDTM | undefined = parties.companies.find(({ role }) => role === EShippingPartyTypes.BOOKING_AGENT);

    if (!bookingAgent || !shipment || !invoice?.billTo) {
      return false;
    }

    const { customerOrgId } = shipment;
    const { organizationId } = invoice.billTo;

    return customerOrgId === organizationId && bookingAgent?.address?.country === 'UK';
  },
);

const getRelatedAdmin = createSelector(
  localState,
  (state) => state.relatedAdmin,
);

export const shipmentBillingInvoiceSelectors = {
  getIsLoading,
  getInvoice,
  getStatus,
  getNumber,
  getTransportationData,
  getTransportationContainers,
  uniqTransportationContainers,
  groupedTransportationContainers,
  getTransportationDataSum,
  getServicesData,
  getServicesDataRest,
  getServicesContainersData,
  getServicesDataSum,
  getFeesData,
  getFeesDataSum,
  getAdditionalData,
  uniqAdditionalContainers,
  groupedAdditionalContainers,
  uniqServicesContainers,
  groupedServicesContainers,
  getAdditionalDataSum,
  getAdditionalRestData,
  getError,
  getSelectedItems,
  getNotes,
  getUpdatedItems,
  getAppliedCharges,
  getCreditNoteError,
  getCreditNoteLoading,
  getCreditNoteSuccess,
  getCreatedCreditNote,
  getIsOpenCreateCreditNote,
  getLinkedCreditNotesItems,
  getLinkedCreditNotesLoading,
  getLinkedCreditNotesError,
  preparedCreditNotesForTable,
  getCharges,
  getIsOpenPayment,
  getInvoiceForPayment,
  getInvoiceBalance,
  getUsedAmount,
  getLeftAmount,
  getPaymentsTable,
  getSumOfUpdatedItems,
  getCreditNoteErrorTitle,
  getGlobalLoading,
  getLinkedCompanyFrom,
  getLinkedCompanyTo,
  getMakePaymentButtonAvailability,
  getIsVATVisible,
  getRelatedAdmin,
};
