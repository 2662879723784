import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { CountrySelectUseCase } from 'app-wrapper/usecases/CountrySelect.useCase';
import { SelectUseCase } from 'app-wrapper/usecases/Select.useCase';
import { BaseController, controller } from 'proto/BaseController';

import { R as AuthR } from 'authentication/repository';

import i18n from 'app-wrapper/i18n/i18n';
import { RouteNames } from 'app-wrapper/constants';
import { validationEmail, validationPhone } from 'app-wrapper/utils';
import { apiWorker } from 'app-wrapper/repository/utilsServices';

import { R } from 'user-management/repository';
import {
  OrganizationDTM,
  BusinessRegistrationNumberDtm,
  OrganizationMembershipDtm,
  OrganizationMemberDTM,
  OrganizationFieldsErrorsFieldDTM,
  OrganizationTradeReferenceDTM,
} from 'user-management/models/dtm';
import { EMembershipType, EOrganizationType } from 'user-management/constants';
import { WebsiteUrl } from 'user-management/models/dtm/WebsiteUrl';
import { NewOrganizationCase } from 'user-management/usecases';

@controller
export class NewOrganizationDataController extends BaseController {
  get stateSelectUseCase() {
    return new SelectUseCase(
      this,
      R.actions.newOrganizationData.setUserOrganizationUpdateAddressState,
      R.actions.newOrganizationData.setUserOrganizationErrorsAddressState,
      this.onValidateOrganizationFields,
    );
  }

  get countrySelectUseCase() {
    return new CountrySelectUseCase(
      this,
      R.actions.newOrganizationData.setUserOrganizationUpdateAddressCountry as ActionCreatorWithPayload<string | undefined, string>,
      R.actions.newOrganizationData.setUserOrganizationErrorsAddressCountry,
      this.onValidateOrganizationFields,
      R.actions.newOrganizationData.setUserOrganizationUpdateAddressState,
    );
  }

  public uploadData = async () => {
    let organization: OrganizationDTM | null;
    let organizationMember: OrganizationMemberDTM | undefined;

    this.dispatch(R.actions.newOrganizationData.setOrganizationDataLoading(true));
    this.dispatch(R.actions.newOrganizationData.resetUserOrganization());
    this.dispatch(R.actions.newOrganizationData.resetUserOrganizationUpdate());
    this.dispatch(R.actions.newOrganizationData.emptyUserOrganizationUpdateMembersContactsAll());

    try {
      this.abortAllRequests();

      organization = await R.services.organization.getCurrentOrganization();
    } catch (e) {
      this.dispatch(R.actions.newOrganizationWizardSteps.setSecondStepDisable(true));
      this.dispatch(R.actions.newOrganizationWizardSteps.setThirdStepDisable(true));
      this.dispatch(R.actions.newOrganizationData.setOrganizationDataLoading(false));

      return;
    }

    if (!organization) {
      this.dispatch(R.actions.newOrganizationData.setOrganizationDataLoading(false));

      return;
    }

    this.dispatch(R.actions.newOrganizationData.setUserOrganization(organization));
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdate(organization));

    if (organization?.tradeReference) {
      this.dispatch(R.actions.newOrganizationData.setTradeReference(organization.tradeReference));
      this.dispatch(R.actions.newOrganizationData.setIsTradeReferenceCollapsed(true));
    }

    const userEmail = AuthR.selectors.auth.getEmail(this.store.getState());

    try {
      organizationMember = await R.services.organization.getOrganizationMember(organization.id, userEmail);
    } catch (e) {
      this.dispatch(R.actions.newOrganizationData.setOrganizationDataLoading(false));
      console.error(e);

      return;
    }

    if (!organizationMember) {
      this.dispatch(R.actions.newOrganizationData.setOrganizationDataLoading(false));

      return;
    }

    this.dispatch(R.actions.newOrganizationData.setOrganizationDataLoading(false));
    this.dispatch(R.actions.newOrganizationData.setCurrentOrganizationUserData(organizationMember));
  };

  public clear = () => {
    this.dispatch(R.actions.newOrganizationData.clear());
    this.dispatch(R.actions.newOrganizationWizardSteps.clear());
  }

  public saveOrganizationDataStep = async () => {
    this.onCheckVisitedOrganizationFields();

    if (this.onValidateOrganizationFields() || !this.isOrganizationDataFieldsUpdated() || !this.checkIfTradeReferenceValidationPassed()) {
      return;
    }

    const isError = await new NewOrganizationCase(this).saveOrganizationData();
    if (isError) {
      throw isError;
    }

    this.navigate(RouteNames.OVERVIEW());
  }

  public checkIfTradeReferenceValidationPassed = () => {
    this.dispatch(R.actions.newOrganizationData.setWasSubmittedOnce(true));

    const shouldValidate = R.selectors.newOrganizationData.getShouldValidateTradeReference(this.store.getState());
    const tradeReference = R.selectors.newOrganizationData.getTradeReference(this.store.getState());

    if (!shouldValidate) {
      return true;
    }

    const {
      companyName,
      firstName,
      lastName,
      email,
      phone,
    } = tradeReference;
    const emailError = email.length ? validationEmail(email).errorMessage : '';
    const phoneError = phone.length ? validationPhone(phone).errorMessage : '';

    return !!(companyName.length && firstName.length && lastName.length && (email.length || phone.length) && !emailError.length && !phoneError.length);
  }

  public nextOrganizationDataStep = async () => {
    this.dispatch(R.actions.newOrganizationData.setWasSubmittedOnce(true));

    this.onCheckVisitedOrganizationFields();

    if (this.onValidateOrganizationFields() || !this.isOrganizationDataFieldsUpdated() || !this.checkIfTradeReferenceValidationPassed()) {
      return;
    }

    try {
      const res = await new NewOrganizationCase(this).saveOrganizationData();
      if (res) {
        throw res;
      }
    } catch (e) {
      console.error('NewOrganizationDataController ERROR: saveOrganizationData');

      return;
    }

    this.navigate(RouteNames.SUBMIT_PROOF_DOCUMENTS_WIZARD_ITEM());
  }

  public cancelWizard = () => {
    this.dispatch(R.actions.newOrganizationData.resetUserOrganization());
    this.dispatch(R.actions.newOrganizationData.resetUserOrganization());
    this.dispatch(R.actions.newOrganizationData.resetUserOrganizationUpdate());

    this.navigate(RouteNames.OVERVIEW());
  }

  public onChangeUpdateName = (name: string) => {
    const validValue = name.slice(0, 100);
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdateName(validValue));

    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsName({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onBlurUpdateName = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsName({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));
    this.onValidateOrganizationFields();
  }

  public onChangeUpdateType = (type: EOrganizationType) => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdateType(type));
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdateIndustry(''));

    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsType({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onBlurUpdateType = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsType({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));
    this.onValidateOrganizationFields();
  }

  public onChangeUpdateIndustry = (value: string) => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdateIndustry(value));

    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsIndustry({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onChangeUpdateAddress1 = (address1: string) => {
    const validValue = address1.slice(0, 50);
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdateAddress1(validValue));

    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddress1({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onBlurUpdateAddress1 = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddress1({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));
    this.onValidateOrganizationFields();
  }

  public onChangeUpdateAddress2 = (address2: string) => {
    const validValue = address2.slice(0, 50);
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdateAddress2(validValue));

    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddress2({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onBlurUpdateAddress2 = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddress2({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));
    this.onValidateOrganizationFields();
  }

  public onChangeUpdateAddressCity = (city: string) => {
    const validValue = city.slice(0, 50);
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdateAddressCity(validValue));

    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddressCity({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onBlurUpdateAddressCity = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddressCity({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));
    this.onValidateOrganizationFields();
  }

  public onChangeUpdateAddressState = (state: string) => {
    this.stateSelectUseCase.onChange(state);
  }

  public onClearUpdateAddressState = () => {
    this.stateSelectUseCase.onClear();
  }

  public onBlurUpdateAddressState = () => {
    this.stateSelectUseCase.onBlur();
  }

  public onChangeUpdateAddressPostalCode = (postalCode: string) => {
    const validValue = postalCode.slice(0, 10);
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdateAddressPostalCode(validValue));

    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddressPostalCode({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onBlurUpdateAddressPostalCode = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddressPostalCode({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));
    this.onValidateOrganizationFields();
  }

  public onChangeUpdateAddressCountry = (country = '') => {
    this.countrySelectUseCase.onChange(country);
  }

  public onClearUpdateAddressCountry = () => {
    this.countrySelectUseCase.onClear();
  }

  public onBlurUpdateAddressCountry = () => {
    this.countrySelectUseCase.onBlur();
  }

  public onChangeUpdatePhone = (phone: string) => {
    const validValue = validationPhone(phone);
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdatePhone(validValue.phone));

    this.onValidateOrganizationFields();
  }

  public onFocusUpdatePhone = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPhone({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onBlurUpdatePhone = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPhone({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));
    this.onValidateOrganizationFields();
  }

  public onChangeUpdatePhone2 = (phone2: string) => {
    const validValue = validationPhone(phone2);
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdatePhone2(validValue.phone));

    this.onValidateOrganizationFields();
  }

  public onFocusUpdatePhone2 = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPhone2({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onBlurUpdatePhone2 = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPhone2({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));
    this.onValidateOrganizationFields();
  }

  public onChangeUpdateEmail = (email: string) => {
    const validValue = validationEmail(email);
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdateEmail(validValue.email));

    this.onValidateOrganizationFields();
  }

  public onFocusUpdateEmail = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsEmail({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onBlurUpdateEmail = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsEmail({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));
    this.onValidateOrganizationFields();
  }

  public onChangeUpdateWebUrl = (webUrl: string) => {
    const validValue = WebsiteUrl.fromPlain({ webUrl }).getValidValue();
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdateWebUrl(validValue));

    this.onValidateOrganizationFields();
  }

  public onFocusUpdateWebUrl = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsWebUrl({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onBlurUpdateWebUrl = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsWebUrl({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));
    this.onValidateOrganizationFields();
  }

  public onChangeUpdateBusinessRegistrationNumber = (businessRegistrationNumber: BusinessRegistrationNumberDtm) => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdateBusinessRegistrationNumber(BusinessRegistrationNumberDtm.fromPlain({
      ...businessRegistrationNumber,
      valueTemp: businessRegistrationNumber.value.replace(/ /g, '').trim().slice(0, 16),
      value: businessRegistrationNumber.value.replace(/ /g, '').trim().slice(0, 16),
    })));

    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsBusinessRegistrationNumber({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onBlurUpdateBusinessRegistrationNumber = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsBusinessRegistrationNumber({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));
    this.onValidateOrganizationFields();
  }

  public onAddUpdateMembership = () => {
    const organizationUpdate = R.selectors.newOrganizationData.getUserOrganizationUpdate(this.store.getState());
    const newMembershipType = organizationUpdate.memberships?.[0].type === EMembershipType.WCA_ID
      ? EMembershipType.DNB_ID : EMembershipType.WCA_ID;

    const newMembership = OrganizationMembershipDtm.createEmptyWithSelectedType(newMembershipType);

    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsMembership(OrganizationFieldsErrorsFieldDTM.fromPlain({
      id: newMembership.id,
      isFocus: false,
      isBlur: true,
      isVisited: true,
    })));

    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdateMemberships(newMembership));
  }

  public onRemoveUpdateMembership = (id: string) => {
    this.dispatch(R.actions.newOrganizationData.removeUserOrganizationUpdateMemberships(id));
  }

  public onChangeUpdateMembershipType = (id: string, type: EMembershipType) => {
    const organizationUpdate = R.selectors.newOrganizationData.getUserOrganizationUpdate(this.store.getState());
    const membership = organizationUpdate.memberships?.find(((item) => item.id === id));

    if (!membership) {
      return;
    }

    const newMembership = OrganizationMembershipDtm.fromPlain({
      id,
      type,
      value: membership.value,
      isNew: membership.isNew,
    });

    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdateMemberships(newMembership));

    this.onValidateOrganizationFields();
  }

  public onChangeUpdateMembershipValue = (id: string, value: string) => {
    const organizationUpdate = R.selectors.newOrganizationData.getUserOrganizationUpdate(this.store.getState());
    const membership = organizationUpdate.memberships?.find(((item) => item.id === id));

    if (!membership) {
      return;
    }

    const validValue = membership.type === EMembershipType.WCA_ID ? value.slice(0, 6) : value.slice(0, 9);

    const newMembership = OrganizationMembershipDtm.fromPlain({
      id,
      value: validValue,
      type: membership.type,
      isNew: membership.isNew,
    });

    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsMembership(OrganizationFieldsErrorsFieldDTM.fromPlain({
      id: newMembership.id,
      isFocus: true,
      isBlur: false,
    })));

    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdateMemberships(newMembership));

    this.onValidateOrganizationFields();
  }

  public onBlurUpdateMembership = (id: string) => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsMembership(OrganizationFieldsErrorsFieldDTM.fromPlain({
      id,
      isFocus: false,
      isBlur: true,
      isVisited: true,
    })));

    this.onValidateOrganizationFields();
  }

  public onChangeUpdateTaxId = (taxId: string) => {
    const validValue = taxId.slice(0, 35);
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdateTaxId(validValue));

    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsTaxId({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onBlurUpdateTaxId = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsTaxId({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));
    this.onValidateOrganizationFields();
  }

  public onChangeUpdatePrimaryName = (id: string) => {
    const { organizationUpdate } = this.store.getState().newOrganizationData;
    const membersContactsValue = organizationUpdate.membersContacts?.filter((item: OrganizationMemberDTM) => item.id === id)?.[0];

    if (membersContactsValue) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdateMembersContactsValue(membersContactsValue));
    }
    this.onValidateOrganizationFields();
  }

  public onBlurUpdatePrimaryName = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsMembersContactsValue({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));
    this.onValidateOrganizationFields();
  }

  public onChangeUpdatePrimaryEmail = (primaryEmail: string) => {
    const validValue = validationEmail(primaryEmail);
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdatePrimaryEmail(validValue.email));

    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPrimaryEmail({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onFocusUpdatePrimaryEmail = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPrimaryEmail({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onBlurUpdatePrimaryEmail = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPrimaryEmail({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));
    this.onValidateOrganizationFields();
  }

  public onChangeUpdatePrimaryPhone = (phone: string) => {
    const validValue = validationPhone(phone);
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdatePrimaryPhone(validValue.phone));

    this.onValidateOrganizationFields();

    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPrimaryPhone({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onFocusUpdatePrimaryPhone = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPrimaryPhone({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onBlurUpdatePrimaryPhone = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPrimaryPhone({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));
    this.onValidateOrganizationFields();
  }

  public onChangeUpdatePrimaryPhone2 = (phone2: string) => {
    const validValue = validationPhone(phone2);
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationUpdatePrimaryPhone2(validValue.phone));

    this.onValidateOrganizationFields();

    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPrimaryPhone2({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onFocusUpdatePrimaryPhone2 = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPrimaryPhone2({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onBlurUpdatePrimaryPhone2 = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPrimaryPhone2({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));
    this.onValidateOrganizationFields();
  }

  public setIsTradeReferenceCollapsed = (isCollapsed: boolean) => {
    this.dispatch(R.actions.newOrganizationData.setIsTradeReferenceCollapsed(isCollapsed));
  };

  public setTradeCompanyName = (name: string) => {
    const tradeReference = R.selectors.newOrganizationData.getTradeReference(this.store.getState());

    this.dispatch(R.actions.newOrganizationData.setTradeReference(OrganizationTradeReferenceDTM.fromPlain({
      ...tradeReference,
      companyName: name,
    })));
    this.dispatch(R.actions.newOrganizationData.setWasSubmittedOnce(false));
  };

  public setTradeFirstName = (name: string) => {
    const tradeReference = R.selectors.newOrganizationData.getTradeReference(this.store.getState());

    this.dispatch(R.actions.newOrganizationData.setTradeReference(OrganizationTradeReferenceDTM.fromPlain({
      ...tradeReference,
      firstName: name,
    })));
    this.dispatch(R.actions.newOrganizationData.setWasSubmittedOnce(false));
  };

  public setTradeLastName = (name: string) => {
    const tradeReference = R.selectors.newOrganizationData.getTradeReference(this.store.getState());

    this.dispatch(R.actions.newOrganizationData.setTradeReference(OrganizationTradeReferenceDTM.fromPlain({
      ...tradeReference,
      lastName: name,
    })));
    this.dispatch(R.actions.newOrganizationData.setWasSubmittedOnce(false));
  };

  public setTradeEmail = (email: string) => {
    const tradeReference = R.selectors.newOrganizationData.getTradeReference(this.store.getState());

    this.dispatch(R.actions.newOrganizationData.setTradeReference(OrganizationTradeReferenceDTM.fromPlain({
      ...tradeReference,
      email,
    })));
    this.dispatch(R.actions.newOrganizationData.setWasSubmittedOnce(false));
  };

  public setTradePhone = (phone: string) => {
    const tradeReference = R.selectors.newOrganizationData.getTradeReference(this.store.getState());

    this.dispatch(R.actions.newOrganizationData.setTradeReference(OrganizationTradeReferenceDTM.fromPlain({
      ...tradeReference,
      phone,
    })));
    this.dispatch(R.actions.newOrganizationData.setWasSubmittedOnce(false));
  };

  public abortAllRequests = () => {
    apiWorker.abortAllRequests();
  }

  // private saveOrganizationData = async () => {
  //   let newOrganization: OrganizationDTM | null = null;
  //   let organization = R.selectors.newOrganizationData.getUserOrganizationUpdate(this.store.getState());
  //   const tradeReference = R.selectors.newOrganizationData.getTradeReference(this.store.getState());
  //   const isTradeReferenceEmpty = R.selectors.newOrganizationData.getIsTradeReferenceEmpty(this.store.getState());

  //   organization = {
  //     ...organization,
  //     tradeReference: isTradeReferenceEmpty ? undefined : tradeReference,
  //   };

  //   try {
  //     if (organization?.id) {
  //       await R.services.organization.putCurrentOrganization(organization);
  //     } else {
  //       newOrganization = await R.services.organization.postCurrentOrganization(organization);
  //     }
  //   } catch (e) {
  //     const error = e as AxiosError<ServerError>;
  //     if (error.message) {
  //       notification.error({
  //         message: error.message,
  //         placement: 'bottomRight',
  //         bottom: 60,
  //         duration: null,
  //       });
  //     }
  //   }

  //   if (newOrganization) {
  //     this.dispatch(R.actions.newOrganizationData.setUserOrganization(newOrganization));
  //   }
  // }

  private isOrganizationDataFieldsUpdated = () => {
    const updatedOrganization = R.selectors.newOrganizationData.getUserOrganizationUpdate(this.store.getState());

    let isUpdate = false;

    if (
      (updatedOrganization?.name && updatedOrganization?.name !== '')
      || (updatedOrganization?.type)
      || (updatedOrganization?.industry)
      || (updatedOrganization?.address?.address1 && updatedOrganization?.address?.address1 !== '')
      || (updatedOrganization?.address?.address2 && updatedOrganization?.address?.address2 !== '')
      || (updatedOrganization?.address?.city && updatedOrganization?.address?.city !== '')
      || (updatedOrganization?.address?.state && updatedOrganization?.address?.state !== '')
      || (updatedOrganization?.address?.closestPort && updatedOrganization?.address?.closestPort !== '')
      || (updatedOrganization?.phone && updatedOrganization?.phone !== '')
      || (updatedOrganization?.email && updatedOrganization?.email !== '')
      || (updatedOrganization?.emailInformation && updatedOrganization?.emailInformation !== '')
      || (updatedOrganization?.webUrl && updatedOrganization?.webUrl.getValidValue() !== '')
      || (updatedOrganization?.phone2 && updatedOrganization?.phone2 !== '')
      || (updatedOrganization?.businessRegistrationNumber.getValidValue() && updatedOrganization?.businessRegistrationNumber.getValidValue() !== '')
      || (updatedOrganization?.taxId && updatedOrganization?.taxId !== '')
      || (updatedOrganization?.memberships?.[0] && updatedOrganization?.memberships[0].getValidValue() !== '')
      || (updatedOrganization?.memberships?.[1] && updatedOrganization?.memberships[1].getValidValue() !== '')
    ) {
      isUpdate = true;
    }

    return isUpdate;
  }

  private onCheckVisitedOrganizationFields = () => {
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsName({
      isVisited: true,
    }));
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsType({
      isVisited: true,
    }));
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsIndustry({
      isVisited: true,
    }));
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddress1({
      isVisited: true,
    }));
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddress2({
      isVisited: true,
    }));
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddressCity({
      isVisited: true,
    }));
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddressState({
      isVisited: true,
    }));
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddressPostalCode({
      isVisited: true,
    }));
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddressCountry({
      isVisited: true,
    }));
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPhone({
      isVisited: true,
    }));
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPhone2({
      isVisited: true,
    }));
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsEmail({
      isVisited: true,
    }));
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsWebUrl({
      isVisited: true,
    }));
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsBusinessRegistrationNumber({
      isVisited: true,
    }));
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAllMembershipsVisited(true));
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsTaxId({
      isVisited: true,
    }));
    this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsMembersContactsValue({
      isVisited: true,
    }));
  }

  private onValidateOrganizationFields = () => {
    const organization = this.store.getState().newOrganizationData.organizationUpdate;
    let isError = false;

    if (organization?.name) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsName({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsName({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.type) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsType({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsType({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.type === EOrganizationType.BCO && !organization?.industry) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsIndustry({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    } else {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsIndustry({
        message: '',
      }));
    }

    if (organization?.address?.address1) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddress1({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddress1({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.address?.city) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddressCity({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddressCity({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.address?.state) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddressState({
        message: '',
      }));
    } else if (organization?.address?.country === 'US') {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddressState({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    } else if (organization?.address?.country && organization?.address?.country !== 'US') {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddressState({
        message: '',
      }));
    } else if (!organization?.address?.country) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddressState({
        message: '',
      }));
    }

    if (organization?.address?.postalCode) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddressPostalCode({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddressPostalCode({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.address?.country) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddressCountry({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsAddressCountry({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.phone && !validationPhone(organization?.phone).errorMessage) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPhone({
        message: '',
      }));
    } else if (organization?.phone && validationPhone(organization?.phone).errorMessage) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPhone({
        message: validationPhone(organization?.phone).errorMessage,
      }));

      isError = true;
    } else {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPhone({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.phone2 && !validationPhone(organization?.phone2).errorMessage) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPhone2({
        message: '',
      }));
    } else if (organization?.phone2 && validationPhone(organization?.phone2).errorMessage) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsPhone2({
        message: validationPhone(organization?.phone2).errorMessage,
      }));

      isError = true;
    }

    if (organization?.email === '') {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsEmail({
        message: '',
      }));
    } else if (organization?.email) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsEmail({
        message: validationEmail(organization?.email).errorMessage,
      }));

      if (validationEmail(organization?.email).errorMessage) {
        isError = true;
      }
    }

    if (organization?.webUrl?.webUrl && !organization?.webUrl?.hasError()) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsWebUrl({
        message: '',
      }));
    } else if (organization?.webUrl?.webUrl && organization?.webUrl?.hasError()) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsWebUrl({
        message: organization?.webUrl.getErrorMessage(),
      }));

      isError = true;
    } else if (!organization?.webUrl?.webUrl) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsWebUrl({
        message: '',
      }));
    }

    if (organization?.businessRegistrationNumber.value) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsBusinessRegistrationNumber({
        message: '',
      }));
    } else if (organization?.businessRegistrationNumber.value === '') {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsBusinessRegistrationNumber({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    // Currently off
    // if (organization?.businessRegistrationNumber.isValid()) {
    //   this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsBusinessRegistrationNumber({
    //     message: '',
    //   }));
    // } else if (organization?.businessRegistrationNumber.value === '') {
    //   this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsBusinessRegistrationNumber({
    //     message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
    //   }));
    //   isError = true;
    // } else {
    //   this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsBusinessRegistrationNumber({
    //     message: i18n.t('basicErrors.NOT_VALID_PATTERN_EIN'),
    //   }));
    //   isError = true;
    // }

    organization?.memberships?.forEach((item) => {
      this.dispatch(R.actions.newOrganizationData.setUserOrganizationErrorsMembership({
        id: item.id,
        message: item.getErrorMessage(),
      }));

      if (item.getErrorMessage()) {
        isError = true;
      }
    });

    return isError;
  }
}
