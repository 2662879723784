import { ForbiddenError, NotFoundError } from 'app-wrapper/models/errors';
import { hasReadAccess } from 'app-wrapper/utils';
import { R as AuthR } from 'authentication/repository';
import isEqual from 'lodash/isEqual';
import moment from 'moment/moment';

import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';
import {
  IGetShipmentPlansResponse,
  putShipmentDocumentsAdditionalDetailsResponse,
} from 'shipment-operations/models/contracts';
import {
  BillOfLadingDTM,
  CargoDTM,
  ChargeDTM,
  ContainerDTM,
  ContainerWithCargoDTM,
  HBLViewChargeDTM,
  IRouteLegDTM,
  MasterBillOfLadingDTM,
  MasterBillOfLadingViewDTM, ModuleFunctionalityPermissionsDtm,
  PaymentTermsDTM,
  ShipmentPreviewDTM,
  ShippingInstructionsPermissionsDtm,
  ShippingPartyDTM,
  ShippingPartyOverviewDTM,
  TransportationOverviewDTM,
  TransportPlanDTM,
} from 'shipment-operations/models/dtm';
import { R } from 'shipment-operations/repository';
import {
  ContractOwner,
  EShipmentConfirmationTypes, EShippingPartyTypes,
  ExportClearanceType, IncotermsType,
  SERVICES_WITH_ITN, ShipmentFreightMode,
  ShippingInstructionsPages,
  TradeType,
} from 'shipment-operations/constants';
import { EAccountDepartmentType } from 'user-management/constants';
import { AdminPublicInfoDTM } from 'user-management/models/dtm';
import { R as userManagementR } from 'user-management/repository';

type ShippingPartyResult = {
  customer: ShippingPartyDTM | undefined,
  forwardingAgent: ShippingPartyDTM | undefined,
  deliveryAgent: ShippingPartyDTM | undefined,
  houseShipper: ShippingPartyDTM | undefined,
  houseConsignee: ShippingPartyDTM | undefined
}

export class BillOfLadingUseCase extends BaseUseCase {
  public async fetchInformationForBillOfLadingPage(shipmentId?: string) {
    if (!shipmentId) {
      console.error('BillOfLadding CONTROLLER ERROR: fetchContainersInformation');
      return;
    }

    this.dispatch(R.actions.billOfLadingCommon.setIsLoading(true));
    this.dispatch(R.actions.billOfLadingCommon.setIsFreightedView(true));
    this.dispatch(R.actions.billOfLadingCommon.setActiveSIPage(ShippingInstructionsPages.MBL_PAGE));

    // Fetching containers with cargos part

    let containers: ContainerDTM[] = [];
    let cargos: CargoDTM[];
    let billOfLading: undefined | BillOfLadingDTM;
    let overview: null | TransportationOverviewDTM;
    let shortShipment: ShipmentPreviewDTM;
    let paymentTerms: PaymentTermsDTM | null;
    let shipmentPermissions: ModuleFunctionalityPermissionsDtm | null;

    const waitPromises: Promise<unknown>[] = [];

    try {
      this.getExportClearance(shipmentId);
      this.getAdditionalServices(shipmentId);
      this.getContainers(shipmentId);
      this.getTemperatureControlData(shipmentId);
      this.getAdditionalDetails(shipmentId);

      const [containersResult, cargosResult, billOfLadingResult, overviewResult, shortShipmentResult, paymentTermsResult, shippingPartyResult] = await Promise.all([
        R.services.shipmentContainers.getContainersList(shipmentId),
        R.services.cargo.getCargos(+shipmentId),
        R.services.billOfLading.getBillOfLading(shipmentId),
        R.services.inttra.getBookingOverview(shipmentId),
        R.services.shipment.getShipmentShortById(+shipmentId),
        R.services.paymentTerms.getShipmentPaymentTerms(shipmentId),
        this.fetchShippingPartiesInformation(shipmentId),
      ]);
      containers = containersResult;
      cargos = cargosResult;
      billOfLading = billOfLadingResult;
      overview = overviewResult;
      shortShipment = shortShipmentResult;
      paymentTerms = paymentTermsResult;

      const userData = AuthR.selectors.auth.getUser(this.store.getState());

      shipmentPermissions = await this.repositories.shipmentPermissionsRepository.get(String(shipmentId), paymentTerms, userData.isAdmin, true) || null;
      if (shipmentPermissions) {
        this.dispatch(R.actions.shipment.setPermissions(shipmentPermissions));
      }

      const shippingParty = shippingPartyResult;

      // fetching shipment plans part

      const [container] = containers;

      waitPromises.push(this.getShipmentPlans(container.planId));

      if (billOfLading) {
        this.dispatch(R.actions.billOfLadingCommon.setBillOfLading({
          type: billOfLading.type,
          copies: billOfLading.copies,
          originals: billOfLading.originals,
        }));
      }

      if (paymentTerms && shortShipment) {
        const { tradeType } = paymentTerms;

        waitPromises.push(this.getOrganizationDepartments(shortShipment));
        waitPromises.push(this.getRelatedAdminPublicInfo(shortShipment));
        waitPromises.push(this.getSIDetails(shipmentId));
        waitPromises.push(this.getConfirmations(shipmentId));
        waitPromises.push(this.getCustomerCharges(shipmentId, shippingParty.customer, paymentTerms));
        waitPromises.push(this.getCustomerChargesWithMediator(shipmentId, shortShipment, shippingParty.forwardingAgent, shippingParty.deliveryAgent, shipmentPermissions?.shippingInstructions));
        waitPromises.push(this.getHouseConsigneeCharges(shipmentId, shippingParty.houseConsignee, shipmentPermissions?.shippingInstructions));
        waitPromises.push(this.getHouseShipperCharges(shipmentId, shippingParty.houseShipper, tradeType, shipmentPermissions?.shippingInstructions));
        waitPromises.push(this.getDeliveryAgentCharges(shipmentId, shippingParty.deliveryAgent, tradeType, shipmentPermissions?.shippingInstructions));
      }
    } catch (e) {
      this.dispatch(R.actions.billOfLadingCommon.setIsLoading(false));

      throw e;
    }

    const containersWithCargos = containers.map((container) => (ContainerWithCargoDTM.fromPlain({
      ...container,
      cargoItems: container.cargoItems.map((cargoItem) => {
        const cargo = cargos.find(({ id = 0 }) => +cargoItem.cargoId === id) as CargoDTM;

        return {
          ...cargoItem,
          cargo,
        };
      }),
    })));

    this.dispatch(R.actions.billOfLadingCommon.setCargos(cargos));
    this.dispatch(R.actions.billOfLadingCommon.setContainers(containersWithCargos));

    this.dispatch(R.actions.billOfLadingCommon.setCarrierName(overview?.carrierName || ''));
    this.dispatch(R.actions.billOfLadingCommon.setCarrierSCAC(overview?.carrierName || ''));
    this.dispatch(R.actions.billOfLadingCommon.setContactName(overview?.contactName || ''));
    this.dispatch(R.actions.billOfLadingCommon.setContactPhones(overview?.phoneList || []));
    this.dispatch(R.actions.billOfLadingCommon.setContactEmails(overview?.emailList || []));
    this.dispatch(R.actions.billOfLadingCommon.setMblNumber(overview?.mblNumber || ''));
    this.dispatch(R.actions.billOfLadingCommon.setContractNumber(overview?.contractNumber || ''));

    this.dispatch(R.actions.shipment.setShipment(shortShipment));

    if (shortShipment) {
      if (shortShipment.isContractOwnerOrigin()) {
        this.dispatch(R.actions.billOfLadingCommon.setContractOwner(ContractOwner.ORIGIN));
      }

      if (shortShipment.isContractOwnerDestination()) {
        this.dispatch(R.actions.billOfLadingCommon.setContractOwner(ContractOwner.DESTINATION));
      }
    }

    const contractOwner = R.selectors.billOfLadingCommon.getContractOwner(this.store.getState());
    const isHBLPage = R.selectors.billOfLadingCommon.getIsHBLPage(this.store.getState());

    if (!paymentTerms || !shortShipment) {
      return;
    }

    if (shortShipment.origin) {
      this.dispatch(R.actions.billOfLadingCommon.setIsOriginUS(shortShipment.origin.countryCode === 'US'));
    }

    const { isSelfService } = shortShipment;
    const { tradeType, incoterm } = paymentTerms;

    if (tradeType === TradeType.EXPORT && (incoterm === IncotermsType.CIF || incoterm === IncotermsType.CFR)) {
      const { originPartnerOrgId, contractOwnerOrgId } = shortShipment;

      this.dispatch(R.actions.billOfLading.setIsOnlyHouseConsigneeAvailable(originPartnerOrgId === contractOwnerOrgId));
    }

    const { origin, freight, destination } = PaymentTermsDTM.getCurrentAvailablePayments(incoterm, tradeType, contractOwner, !!isSelfService, isHBLPage);

    this.dispatch(R.actions.billOfLadingCommon.setPaymentTerms(PaymentTermsDTM.fromPlain({
      ...paymentTerms,
      origin,
      freight,
      destination,
    })));

    const mblDraftId = shortShipment && shortShipment.mblDraftId ? String(shortShipment.mblDraftId) : '';
    const mblFinalId = shortShipment && shortShipment.mblFinalId ? String(shortShipment.mblFinalId) : '';

    let draftMBL: MasterBillOfLadingDTM | null = null;

    if (mblDraftId) {
      draftMBL = await this.getMasterBillOfLading(shipmentId, mblDraftId);
    }

    let finalMBL: MasterBillOfLadingDTM | null = null;

    if (mblFinalId) {
      finalMBL = await this.getMasterBillOfLading(shipmentId, mblFinalId);
    }

    let draftMasterBillOfLadingViewDTM: MasterBillOfLadingViewDTM | null = null;
    let finalMasterBillOfLadingViewDTM: MasterBillOfLadingViewDTM | null = null;

    // If there is no draftMBL we must use data from finalMBL
    if (draftMBL) {
      draftMasterBillOfLadingViewDTM = MasterBillOfLadingViewDTM.fromMasterBillOfLadingDTM(draftMBL, overview, billOfLading);
    }

    if (!draftMBL && finalMBL) {
      draftMasterBillOfLadingViewDTM = MasterBillOfLadingViewDTM.fromMasterBillOfLadingDTM(finalMBL, overview, billOfLading);
    }

    if (finalMBL) {
      finalMasterBillOfLadingViewDTM = MasterBillOfLadingViewDTM.fromMasterBillOfLadingDTM(finalMBL, overview, billOfLading);
    }

    this.dispatch(R.actions.billOfLadingCommon.setDraftMBL(draftMasterBillOfLadingViewDTM));
    this.dispatch(R.actions.billOfLadingCommon.setFinalMBL(finalMasterBillOfLadingViewDTM));

    await Promise.all(waitPromises);

    this.dispatch(R.actions.billOfLadingCommon.setIsLoading(false));
  }

  private async getMasterBillOfLading(shipmentId: string, mblId: string): Promise<MasterBillOfLadingDTM | null> {
    return R.services.masterBillOfLading.getMasterBillOfLadingById(shipmentId, mblId);
  }

  private findLowestSequenceLeg = (legs: IRouteLegDTM[]): IRouteLegDTM => {
    let lowestSequence = legs[0].sequence;
    let lowestLeg = legs[0];

    legs.forEach((leg) => {
      if (leg.sequence < lowestSequence) {
        lowestSequence = leg.sequence;
        lowestLeg = leg;
      }
    });

    return lowestLeg;
  }

  private findHighestSequenceLeg(legs: IRouteLegDTM[]): IRouteLegDTM {
    let highestSequence = legs[0].sequence;
    let highestLeg = legs[0];

    legs.forEach((leg) => {
      if (leg.sequence > highestSequence) {
        highestSequence = leg.sequence;
        highestLeg = leg;
      }
    });

    return highestLeg;
  }

  private findTransportationByLeg(leg: IRouteLegDTM, transportations: IGetShipmentPlansResponse['transportations']) {
    return transportations.find(({ transportLeg }) => transportLeg === leg.id);
  }

  private getSeaLegsByTransportation(legs: IRouteLegDTM[], transportations: IGetShipmentPlansResponse['transportations']) {
    const seaTransportations = transportations.filter(({ transport }) => transport.type === ShipmentFreightMode.SEA);

    return seaTransportations.map(({ transportLeg }) => legs.find(({ id }) => id === transportLeg) as IRouteLegDTM);
  }

  public async fetchShippingPartiesInformation(shipmentId: string) {
    let shippingParties: ShippingPartyDTM[] = [];

    shippingParties = await R.services.shippingParties.getList(shipmentId);

    const shipper = shippingParties.find(({ role }) => role === EShippingPartyTypes.SHIPPER);
    const consignee = shippingParties.find(({ role }) => role === EShippingPartyTypes.CONSIGNEE);
    const notifyParty = shippingParties.find(({ role }) => role === EShippingPartyTypes.NOTIFY_PARTY);
    const houseShipper = shippingParties.find(({ role }) => role === EShippingPartyTypes.HOUSE_SHIPPER);
    const houseConsignee = shippingParties.find(({ role }) => role === EShippingPartyTypes.HOUSE_CONSIGNEE);
    const houseNotifyParty = shippingParties.find(({ role }) => role === EShippingPartyTypes.HOUSE_NOTIFY_PARTY);
    const forwardingAgent = shippingParties.find(({ role }) => role === EShippingPartyTypes.FORWARDER_AGENT);
    const oceanCarrier = shippingParties.find(({ role }) => role === EShippingPartyTypes.OCEAN_CARRIER);
    const bookingAgent = shippingParties.find(({ role }) => role === EShippingPartyTypes.BOOKING_AGENT);
    const ultimateCustomer = shippingParties.find(({ role }) => role === EShippingPartyTypes.ULTIMATE_CUSTOMER);
    const customer = shippingParties.find(({ role }) => role === EShippingPartyTypes.CUSTOMER);
    const deliveryAgent = shippingParties.find(({ role }) => role === EShippingPartyTypes.DELIVERY_AGENT);
    const secondNotify = shippingParties.find(({ role }) => role === EShippingPartyTypes.SECOND_NOTIFY_PARTY);

    if (oceanCarrier) {
      const { company } = oceanCarrier;

      if (company?.scac) {
        this.dispatch(R.actions.billOfLadingCommon.setCarrierSCAC(company?.scac || ''));
      }
    }

    this.dispatch(R.actions.billOfLadingCommon.setShippingParties({
      shipper: await this.getFullShippingParty(shipper) || ShippingPartyOverviewDTM.createEmpty(),
      consignee: await this.getFullShippingParty(consignee) || ShippingPartyOverviewDTM.createEmpty(),
      notifyParty: await this.getFullShippingParty(notifyParty) || ShippingPartyOverviewDTM.createEmpty(),
      houseShipper: await this.getFullShippingParty(houseShipper) || ShippingPartyOverviewDTM.createEmpty(),
      houseConsignee: await this.getFullShippingParty(houseConsignee) || ShippingPartyOverviewDTM.createEmpty(),
      houseNotifyParty: await this.getFullShippingParty(houseNotifyParty) || ShippingPartyOverviewDTM.createEmpty(),
      forwardingAgent: await this.getFullShippingParty(forwardingAgent) || ShippingPartyOverviewDTM.createEmpty(),
      oceanCarrier: await this.getFullShippingParty(oceanCarrier) || ShippingPartyOverviewDTM.createEmpty(),
      bookingAgent: await this.getFullShippingParty(bookingAgent) || ShippingPartyOverviewDTM.createEmpty(),
      ultimateCustomer: await this.getFullShippingParty(ultimateCustomer) || ShippingPartyOverviewDTM.createEmpty(),
      customer: await this.getFullShippingParty(customer) || ShippingPartyOverviewDTM.createEmpty(),
      deliveryAgent: await this.getFullShippingParty(deliveryAgent) || ShippingPartyOverviewDTM.createEmpty(),
      secondNotify: await this.getFullShippingParty(secondNotify) || ShippingPartyOverviewDTM.createEmpty(),
    }));

    return {
      customer,
      forwardingAgent,
      deliveryAgent,
      houseShipper,
      houseConsignee,
    };
  }

  private async getFullShippingParty(shippingParty?: ShippingPartyDTM) {
    if (!shippingParty || !shippingParty.company) {
      return null;
    }

    const company = shippingParty.company || null;
    const address = shippingParty.address || null;
    const contact = shippingParty.contact || null;

    const shippingPartyOverview = ShippingPartyOverviewDTM.fromPlain({
      id: shippingParty.id,
      companyName: company?.name,
      addressLine: [
        address?.address1,
        address?.address2,
        address?.city,
        address?.state,
        address?.postalCode,
        address?.country,
      ].filter((v) => !!v).join(', '),
      address: address || undefined,
      contactPerson: contact?.fullName,
      email: contact?.email,
      phone: contact?.phone,
      additionalPhone: contact?.phone2,
      taxId: company?.taxId,
      references: shippingParty.references,
      isFreightForwarderOrgType: shippingParty.isFreightForwarderOrgType,
    });

    if (!shippingPartyOverview.isValid()) {
      console.error(shippingPartyOverview.validate());
    }

    return shippingPartyOverview;
  }

  private async getAdditionalServices(shipmentId: string): Promise<void> {
    const services = await R.services.additionalService.getAdditionalServices(shipmentId);
    const serviceWithITN = services.find(({ code }) => SERVICES_WITH_ITN.includes(code));

    this.dispatch(R.actions.billOfLadingCommon.setHasAdditionalServicesWithITNActivity(!!serviceWithITN));
  }

  private async getExportClearance(shipmentId: string): Promise<void> {
    const customs = await R.services.exportClearance.getExportClearance(shipmentId);
    const customsITN = customs.find((custom) => custom.type === ExportClearanceType.ITN);

    this.dispatch(R.actions.billOfLadingCommon.setExportCustoms(customsITN));
  }

  private async getContainers(shipmentId: string): Promise<void> {
    const containersTracking = await R.services.shipmentTracker.getContainers(shipmentId);
    const [containerTracking] = containersTracking;

    if (containerTracking) {
      this.dispatch(R.actions.billOfLading.setIsVesselDeparted(containerTracking.getIsVesselDeparted()));
    }
  }

  private async getShipmentPlans(planId: string): Promise<void> {
    let plans: TransportPlanDTM[] = [];

    if (planId) {
      plans = await R.services.shipmentPlans.getShipmentPlans(planId);
    }

    const [plan] = plans;

    if (plan) {
      const { route, transportations } = plan;
      const { legs } = route;
      const lowestLeg = this.findLowestSequenceLeg(legs);
      const seaLegs = this.getSeaLegsByTransportation(legs, transportations);
      const lowestSeaLeg = this.findLowestSequenceLeg(seaLegs);
      const highestSeaLeg = this.findHighestSequenceLeg(seaLegs);
      const lowestTransportation = this.findTransportationByLeg(lowestSeaLeg, transportations);
      const highestTransportation = this.findTransportationByLeg(highestSeaLeg, transportations);
      const highestLeg = this.findHighestSequenceLeg(legs);

      this.dispatch(R.actions.billOfLadingCommon.setLowestSequenceLeg(lowestLeg));
      this.dispatch(R.actions.billOfLadingCommon.setHighestSequenceLeg(highestLeg));
      this.dispatch(R.actions.billOfLadingCommon.setLowestSequenceSeaLeg(lowestSeaLeg));
      this.dispatch(R.actions.billOfLadingCommon.setHighestSequenceSeaLeg(highestSeaLeg));

      if (lowestTransportation) {
        this.dispatch(R.actions.billOfLadingCommon.setLowestSequenceTransportation(lowestTransportation));
      }

      if (highestTransportation) {
        const { schedule } = highestTransportation;
        const { arrivalTime } = schedule;

        const differenceInHours = moment.duration(moment(arrivalTime).diff(moment())).asHours();

        if (differenceInHours > 168) {
          this.dispatch(R.actions.billOfLading.setIsEnoughTimeRemainingBeforeVesselArrival(true));
        }
      }
    }
  }

  private async getTemperatureControlData(shipmentId: string): Promise<void> {
    // fetching temperature control part

    const temperatureControl = await R.services.temperatureControl.getTemperatureControlData(shipmentId);

    if (temperatureControl) {
      this.dispatch(R.actions.billOfLadingCommon.setTemperatureControl(temperatureControl));
    }
  }

  private async getAdditionalDetails(shipmentId: string): Promise<void> {
    // fetching additional details part

    let additionalDetails: putShipmentDocumentsAdditionalDetailsResponse | null;

    try {
      additionalDetails = await R.services.shipmentDocument.getAdditionalDetails(+shipmentId);
    } catch (e) {
      this.dispatch(R.actions.billOfLadingCommon.setIsLoading(false));

      throw e;
    }

    this.dispatch(R.actions.billOfLadingCommon.setAdditionalInformationReference(additionalDetails?.references[0]?.value || ''));
  }

  private async getSIDetails(shipmentId: string): Promise<void> {
    // fetching shipment instructions details
    const details = await R.services.shipmentInstructionDetails.getSIDetails(shipmentId);

    if (details) {
      this.dispatch(R.actions.billOfLadingCommon.setSIDetails(details));

      const siMissMatches = await R.services.shipmentChanges.getShipmentChangesBL(shipmentId);

      this.dispatch(R.actions.billOfLadingCommon.setSIMissMatches(siMissMatches.filter(({ current, previous }) => !isEqual(current, previous))));
    }
  }

  private async getConfirmations(shipmentId: string): Promise<void> {
    const confirmations = await R.services.shipment.getConfirmations(shipmentId) || [];

    if (confirmations.length) {
      this.dispatch(R.actions.billOfLadingCommon.setIsDraftHBLApproved(!!confirmations.find(({ type }) => type === EShipmentConfirmationTypes.DRAFT_HBL)));
      this.dispatch(R.actions.billOfLadingCommon.setIsDraftMBLApproved(!!confirmations.find(({ type }) => type === EShipmentConfirmationTypes.DRAFT_MBL)));
    }
  }

  private async getRelatedAdminPublicInfo(shortShipment: ShipmentPreviewDTM): Promise<void> {
    const currentOrg = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());
    let currentOrgRelatedAdmin: AdminPublicInfoDTM | null = null;

    if (currentOrg) {
      try {
        currentOrgRelatedAdmin = await userManagementR.services.organization.getRelatedAdminPublicInfo(currentOrg.id);
      } catch (e) { // temporarily fix, need to prevent request if user has not permission
        if (e instanceof NotFoundError) {
          console.error(e);
        } else {
          throw e;
        }
      }

      this.dispatch(R.actions.billOfLadingCommon.setIsUserFromDestinationPartner(shortShipment.destinationPartnerOrgId === currentOrg.id));
      this.dispatch(R.actions.documentHBLPDF.setCurrentOrgRelatedAdminPublicInfo(currentOrgRelatedAdmin));
      this.dispatch(R.actions.billOfLadingCommon.setCurrentOrgRelatedAdminPublicInfo(currentOrgRelatedAdmin));
    }
  }

  private async getOrganizationDepartments(shortShipment: ShipmentPreviewDTM): Promise<void> {
    if (shortShipment?.accountHolderOrgId) {
      const accountHolderDepartments = await userManagementR.services.accountDepartment.getOrganizationDepartments({
        organizationId: String(shortShipment?.accountHolderOrgId),
      });
      const accountHolderDepartment = accountHolderDepartments.find(({ type }) => type === EAccountDepartmentType.DOCUMENTATION_OPS_EXPORT) || null;

      this.dispatch(R.actions.documentHBLPDF.setAccountHolderRelatedDocsExportDepartment(accountHolderDepartment));
      this.dispatch(R.actions.billOfLadingCommon.setAccountHolderDocOpsExportDepartment(accountHolderDepartment));
    }
  }

  private async getCustomerChargesWithMediator(shipmentId: string, shortShipment: ShipmentPreviewDTM, forwardingAgent: ShippingPartyResult['forwardingAgent'], deliveryAgent: ShippingPartyResult['deliveryAgent'], sIPermissions: ShippingInstructionsPermissionsDtm | undefined): Promise<void> {
    let chargesMBL: ChargeDTM[] = [];

    let mediatorId: number | undefined;

    if (shortShipment?.isContractOwnerOrigin()) {
      mediatorId = forwardingAgent?.company?.id;
    }

    if (shortShipment?.isContractOwnerDestination()) {
      mediatorId = deliveryAgent?.company?.id;
    }

    if (hasReadAccess(sIPermissions?.MBLVisible)) {
      try {
        chargesMBL = await R.services.shipmentCharges.getCustomerCharges(shipmentId, mediatorId);
      } catch (e) { // temporarily fix, need to prevent request if user has not permission
        if (e instanceof ForbiddenError) {
          console.error(e);
        } else {
          throw e;
        }
      }

      this.dispatch(R.actions.billOfLadingCommon.setChargesMBL(chargesMBL));
    }
  }

  private async getCustomerCharges(shipmentId: string, customer: ShippingPartyResult['customer'], paymentTerms: PaymentTermsDTM): Promise<void> {
    let charges: ChargeDTM[] = [];

    if (customer && customer.company && customer.company.id) {
      charges = await R.services.shipmentCharges.getCustomerCharges(shipmentId, customer.company.id);
    }

    this.dispatch(R.actions.billOfLadingCommon.setCharges(charges));
    this.dispatch(R.actions.billOfLadingCommon.setHBLCharges(HBLViewChargeDTM.getHBLViewCharges(charges, paymentTerms as PaymentTermsDTM)));
  }

  private async getHouseConsigneeCharges(shipmentId: string, houseConsignee: ShippingPartyResult['houseConsignee'], sIPermissions: ShippingInstructionsPermissionsDtm | undefined): Promise<void> {
    if (houseConsignee?.company && hasReadAccess(sIPermissions?.MBLVisible)) {
      const houseConsigneeCharges = await R.services.shipmentCharges.getCustomerCharges(shipmentId, houseConsignee.company.id);

      this.dispatch(R.actions.billOfLadingCommon.setHouseConsigneeCharges(houseConsigneeCharges));
    }
  }

  private async getHouseShipperCharges(shipmentId: string, houseShipper: ShippingPartyResult['houseShipper'], tradeType: TradeType, sIPermissions: ShippingInstructionsPermissionsDtm | undefined): Promise<void> {
    if (houseShipper?.company && hasReadAccess(sIPermissions?.MBLVisible) && tradeType === TradeType.IMPORT) {
      const houseShipperCharges = await R.services.shipmentCharges.getCustomerCharges(shipmentId, houseShipper.company.id);

      this.dispatch(R.actions.billOfLadingCommon.setHouseShipperCharges(houseShipperCharges));
    }
  }

  private async getDeliveryAgentCharges(shipmentId: string, deliveryAgent: ShippingPartyResult['deliveryAgent'], tradeType: TradeType, sIPermissions: ShippingInstructionsPermissionsDtm | undefined): Promise<void> {
    if (deliveryAgent?.company && hasReadAccess(sIPermissions?.MBLVisible) && tradeType === TradeType.IMPORT) {
      const deliveryAgentCharges = await R.services.shipmentCharges.getCustomerCharges(shipmentId, deliveryAgent.company.id);

      this.dispatch(R.actions.billOfLadingCommon.setDeliveryAgentCharges(deliveryAgentCharges));
    }
  }
}
